import React from 'react';
import SEO from '../components/Seo';
import { graphql, Link } from 'gatsby';
import DesktopLayout from '../layouts/Desktop';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <DesktopLayout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <div>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <Link to="/">Go To HomePage</Link>
      </div>
    </DesktopLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      excerpt
    }
  }
`;

export default BlogPost;
